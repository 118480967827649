const sponsors = [
  {
    name: 'Archdiocese of Baltimore',
    website: 'https://www.archbalt.org/',
    logo: '',
  },
  {
    name: 'Vaccaro’s Italian Pastry Shop',
    website: 'https://www.vaccarospastry.com/',
    logo: '',
  },
];

export default sponsors;
